export function calculateFrenchReadability(text) {
    const totalWords = text.split(/\s+/).filter(word => word).length;
    const totalSentences = text.split(/[.?!]\s+/).filter(sentence => sentence).length;
    const totalSyllables = text.split(/\s+/).filter(word => word).reduce((count, word) => count + countSyllables(word), 0);

    return 206.835 - (1.015 * (totalWords / totalSentences)) - (73.6 * (totalSyllables / totalWords));
}

export function countSyllables(word) {
    // Remove diacritics and convert to lower case
    const normalizedWord = word.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    // Define vowels and special cases
    const vowels = 'aeiouyàâéèêëîïôùûüÿœæ';
    const specialCases = ['eau', 'oue'];

    // Special case handling
    for (const specialCase of specialCases) {
        word = word.replace(new RegExp(specialCase, 'g'), 'a'); // Replace special case with a single vowel
    }

    let count = 0;
    let prevCharWasVowel = false;

    // Count syllables based on vowels
    for (const char of word) {
        if (vowels.includes(char)) {
            if (!prevCharWasVowel) {
                count += 1;
            }
            prevCharWasVowel = true;
        } else {
            prevCharWasVowel = false;
        }
    }

    // Handle 'e' at the end of words (except after 'i')
    if (word.endsWith('e') && !word.endsWith('ie')) {
        count -= 1;
    }

    return count < 1 ? 1 : count;
}


export function getReadabilityLabel(score) {
    if (score >= 90) return 'Très facile à lire. Facilement compréhensible par un élève moyen de 11 ans.';
    if (score >= 80) return 'Facile à lire. Style conversationnel pour les consommateurs.';
    if (score >= 70) return 'Plutôt facile à lire.';
    if (score >= 60) return 'En clair. Facilement compréhensible par les élèves de 13 à 15 ans.';
    if (score >= 50) return 'Plutôt difficile à lire.';
    if (score >= 30) return 'Difficile à lire.';
    return 'Très difficile à lire. Mieux compris par les diplômés universitaires.';
}


// Test with some French text
// const text = "Ceci est un test de lisibilité. Vérifiez le score!";
// const score = calculateFrenchReadability(text);
// const label = getReadabilityLabel(score);
// console.log("French Readability Score:", score);
// console.log("Readability Level:", label);