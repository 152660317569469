// index.js
import * as React from 'react';
import SeoText from '../components/SeoText';
import { TagCloud } from 'react-tagcloud'
import { calculateFrenchReadability, getReadabilityLabel } from '../utils/readability';
import { getWordCount, getSentenceCount, getParagraphCount, getFrequentKeywords, stopWords } from '../utils/textAnalysis';
import Layout from '../components/layout';
import FAQ from '../components/faq';

const topKFrequent = (nums, k) => {
  let hash = {}

  for (let num of nums) {
      if (!hash[num]) hash[num] = 0
      hash[num]++
  }

  const hashToArray = Object.entries(hash)
  const sortedArray = hashToArray.sort((a,b) => b[1] - a[1])
  const sortedElements = sortedArray.map(num => num[0])
  return sortedElements.slice(0, k)
}

const customRenderer = (tag, size, color) => (
  <span
    key={tag.value}>
    {tag.value}
  </span>
)


const WordCloud = ({text, ngramType = "unigram"}) => {
  if (!text || !text.length) return null;
  
  const countsSorted = getFrequentKeywords(text, ngramType, stopWords);
  
  let data = countsSorted.map(item => {
    return {value: item.keyword, count: item.count};
  });

  return (
    <TagCloud
      minSize={12}
      maxSize={35}
      tags={data}
      // colorOptions={{luminosity: 'light', hue: 'blue'}}
    />
  );
}


const WordTable = ({ title, words }) => (
  <div className="flex-1">
    <span className="font-semibold">{title}</span>
    <table className="table-auto w-full mt-2">
      <tbody className="text-left">
        {words.length > 0 ? (words.map((wordObj, index) => (
          <tr key={index}>
            <td className="border-b-2 border-t-2">{wordObj.keyword}</td>
            <td className="border-b-2 border-t-2">{wordObj.count}</td>
          </tr>
        ))) : (
          <tr>
            <td className="text-gray-400 border-b-2 border-t-2">Aucun mot trouvé</td>
          </tr>
        )
      }
      </tbody>
    </table>
  </div>
);


export class IndexPage extends React.Component {

  state = {
    textArea: "",
    wordCount: 0,
    charCount: 0,
    sentenceCount: 0,
    paragraphCount: 0,
    readabilityScore: null,
    readabilityLabel: null,
    frequentKeywords: [],
    frequentBigrams: [],
  }

  handleChange = (e) => {
    const content = e.target.value
    this.setState({
      textArea: content,
    })
    if (!content.length){
      this.setState({
        charCount: 0,
        wordCount: 0,
        sentenceCount: 0,
        paragraphCount: 0,
        frequentKeywords: [],
        frequentBigrams: [],
      })
      return
    }
    const readabilityScore = calculateFrenchReadability(content);
    const readabilityLabel = getReadabilityLabel(readabilityScore);
    const frequentKeywords = getFrequentKeywords(content)
    const frequentBigrams = getFrequentKeywords(content, "bigram_trigram")
    this.setState({
      charCount: content.length,
      wordCount: getWordCount(content),
      sentenceCount: getSentenceCount(content),
      paragraphCount: getParagraphCount(content),
      readabilityScore,
      readabilityLabel,
      frequentKeywords,
      frequentBigrams,
    })

  }




  render() {

    const { textArea, wordCount, charCount, sentenceCount, paragraphCount, readabilityLabel,frequentKeywords, frequentBigrams } = this.state


    return (
      <Layout>
            <div className="">
              <h1 className="text-4xl sm:text-4xl lg:text-5xl leading-none font-extrabold tracking-tight text-gray-900 mt-4 mb-4 sm:mt-4 sm:mb-4 dark:text-white">Compteur de Mots en Ligne</h1>
              <p className="text-1xl lg:text-2xl tracking-tight mb-10 dark:text-gray-300">Comptez facilement les mots, caractères, phrases et paragraphes avec notre outil gratuit.</p>
              
              <textarea value={textArea} onChange={this.handleChange} className="resize-none border rounded-md py-1 pl-2 w-full h-52" placeholder="Écrivez ou collez votre texte ici..."/>
        
              <div className="mt-2 flex flex-wrap space-x-0 sm:space-x-1">
                <div className="flex-1 flex-col rounded-md bg-gray-100 text-black font-semibold text-sm  flex items-center justify-center py-3 px-6">
                  <span>{ wordCount }</span> 
                  <span>Mots</span> 
                </div>
                <div className="flex-1 flex-col rounded-md bg-gray-100 text-black font-semibold text-sm  flex items-center justify-center py-3 px-6">
                  <span>{ charCount }</span> 
                  <span>Caractères</span> 
                </div>
                <div className="flex-1 flex-col rounded-md bg-gray-100 text-black font-semibold text-sm  flex items-center justify-center py-3 px-6">
                  <span>{sentenceCount}</span> 
                  <span>Phrases</span> 
                </div>
                <div className="flex-1 flex-col rounded-md bg-gray-100 text-black font-semibold text-sm flex items-center justify-center py-3 px-6">
                    <span>{paragraphCount}</span> 
                    <span>Paragraphes</span>
                </div>
              </div>
              <div className="flex flex-col rounded-md bg-gray-100 text-black font-semibold text-sm items-center justify-center py-3 px-6 mt-2 w-full">
                <span>Niveau de lisibilité </span>
                  {textArea.length > 0 ? (
                    <span className="text-gray-600">{readabilityLabel}</span>
                  ) : (
                    <span className="text-gray-400">Commencez à écrire pour obtenir un score de lisibilité</span>
                  )}
              </div>


            </div>

          <div className="mt-2 flex flex-wrap space-x-0 sm:space-x-1">
            <div className="flex-1 flex-col rounded-md bg-gray-100 text-black text-sm  flex items-center justify-center py-3 px-6">
                <WordTable title="Mots-clés fréquents :" words={frequentKeywords} />
            </div>
              <div className="flex-1 flex-col rounded-md bg-gray-100 text-black text-sm flex items-center justify-center py-3 px-6">
                <WordTable title="Bi et Trigrammes fréquents :" words={frequentBigrams} />
            </div>
          </div>


            <h2 className="text-2xl font-bold mt-4">Nuage de mots clés</h2>
            <p>Commencez à écrire ou collez un texte pour afficher les mots clés les plus fréquents en temps réel sous forme de nuage de mots</p>
            { textArea && textArea.length > 5 &&
              <WordCloud text={textArea} /> ||
              <WordCloud text="nuage de mots clés" /> 
            }
            
      
            <SeoText />

            <FAQ /> {/* FAQ section */}
      
    
      </Layout>
    )
  }

}

export default IndexPage
