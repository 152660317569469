import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const FAQ = () => {
  const [openFAQIndex, setOpenFAQIndex] = useState(-1);

  const handleFAQClick = (index) => {
    setOpenFAQIndex(openFAQIndex === index ? -1 : index);
  };

    const data = useStaticQuery(graphql`
    query {
      allFaqsYaml {
        nodes {
          question
          answer
        }
      }
    }
  `);

  const faqs = data.allFaqsYaml.nodes;

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-4">Questions Fréquentes</h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border rounded-md p-4">
            <button
              className="flex justify-between items-center w-full text-left"
              onClick={() => handleFAQClick(index)}
            >
              <span className="text-lg font-semibold">{faq.question}</span>
              <span>{openFAQIndex === index ? '-' : '+'}</span>
            </button>
            <p className={`mt-2 ${openFAQIndex === index ? 'block' : 'hidden'} text-left`}>{faq.answer}</p> {/* Toggle visibility */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
