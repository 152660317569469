// SeoText.js
import * as React from "react"

const SeoText = () => {
  return (
    <div class="text-left mt-10 dark:text-gray-50">
      <h2 class="text-2xl font-bold">Nombre de caractères typiques pour:</h2>
      <table class="table-fixed w-full mt-3">
        <thead>
          <tr>
            <th>Réseaux sociaux</th>
            <th>Nombre de caractères</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Twitter</td>
            <td>71–100</td>
          </tr>
          <tr>
            <td>Facebook</td>
            <td>80</td>
          </tr>
          <tr>
            <td>Instagram</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Description YouTube </td>
            <td>138–150</td>
          </tr>
        </tbody>
      </table>

      <table class="table-fixed w-full mt-3">
        <thead>
          <tr>
            <th>Blogues, travail et littérature</th>
            <th>Nombre de Mots</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Article LinkedIn </td>
            <td>1,900 – 2,000</td>
          </tr>

          <tr>
            {" "}
            <td>Articles de blog</td> <td> 500 – 800</td>{" "}
          </tr>
          <tr>
            {" "}
            <td>Lettres de motivation</td> <td> 200 – 400</td>{" "}
          </tr>
          <tr>
            {" "}
            <td>C.V.</td> <td> 400 – 800</td>{" "}
          </tr>
          <tr>
            {" "}
            <td>Histoires courtes</td> <td> ~ 7,500</td>{" "}
          </tr>
          <tr>
            {" "}
            <td>Nouvelles</td> <td> ~ 17,500 – 40,000</td>{" "}
          </tr>
          <tr>
            {" "}
            <td>Roman</td> <td> ~ 70,000 – 120,000</td>{" "}
          </tr>
          <tr>
            {" "}
            <td>Essai</td> <td> 500 à 700</td>{" "}
          </tr>
          <tr>
            {" "}
            <td>Dissertation</td> <td> 5 à 8 pages</td>{" "}
          </tr>
          <tr>
            {" "}
            <td>Article Wikipedia</td> <td>2,106</td>{" "}
          </tr>
        </tbody>
      </table>


      <table class="table-fixed w-full mt-3">
        <thead>
          <tr>
            <th>Romans célèbres</th>
            <th>Nombre de mots</th>
          </tr>
        </thead>
        <tbody>

        <tr> <td>Harry Potter et la pierre philosophale</td><td> 76,944</td></tr>
        <tr> <td>Le Seigneur des Anneaux : La Communauté de l&#39;Anneau</td><td> 187,790</td></tr>
        <tr> <td>La Ferme de Animaux</td><td> 29,966</td></tr>
        <tr> <td>Ulysses</td><td> 265,222</td></tr>
        <tr> <td>Des souris et des hommes</td><td> 29,160</td></tr>
        <tr> <td>Orgueil et Préjugés</td><td> 122,685</td></tr>
        <tr> <td>Atlas Shrugged</td><td> 561,996</td></tr>
        <tr> <td>Gatsby le magnifique</td><td> 47,094</td></tr>
        <tr> <td>Le lion, la sorcière et la garde-robe</td><td> 38421</td></tr>
        <tr> <td>Charlie et la chocolaterie</td><td> 30644</td></tr>

        </tbody>
      </table>
      <table class="table-fixed w-full mt-3">
        <thead>
          <tr>
            <th>Livres célèbres</th>
            <th>Mots</th>
            <th>Caractères</th>
            <th>Phrases</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>La Bible</td>
            <td>783,137</td>
            <td>4,073,275</td>
          </tr>
          <tr>
            <td>Le Coran</td>
            <td> 77,439</td>
            <td> 320 015</td>
          </tr>
          <tr>
            <td>Le Petit Livre Rouge</td>
            <td>42,766</td>
            <td>266,390</td>
            <td>2 474</td>
          </tr>


        </tbody>
      </table>

      <h3 className="text-1xl font-bold">Sources des statistiques:</h3>
      <p>
        <a className="hover:text-sky-500" href="http://fr.wikicount.net"> Nombres d'articles et de mots sur Wikipedia</a>
        <br/>
        <a className="hover:text-sky-500" href="https://fr.wikipedia.org/wiki/Wikipédia:Statistiques"> Statistiques par Wikipedia pour Wikipedia.</a>
      </p>

    </div>
  )
}

export default SeoText
